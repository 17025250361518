import { Avatar, Col, Layout, Row, Space, Typography, Button, Form, Spin, Input, message, Upload, Modal } from 'antd';
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import Title from 'antd/es/typography/Title';
import Reviews from '../../../../leadgen/components/reviews/Reviews';
import './organization.scss';
import { useDispatch, useSelector } from 'react-redux';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import editImgIcon from '../../../../assets/images/editImg.png';
import { UserOutlined } from '@ant-design/icons';
import { setIsCompanyFormEditable } from '../../../features/myAccountSlice';
import verifiedIcon from '../../../../assets/images/verified.png';

const { Content } = Layout;
const { Text } = Typography;

const Organization = ({
  stats,
  industry,
  companyData,
  loading,
  isFormEditable,
  handleUpload,
  organizationReviews,
  handleUploadPhotoGallery,
  previewUrls,
  setPreviewUrls,
  setDeletedFiles,
  logoPreviewUrl,
}) => {
  const dispatch = useDispatch();
  const userRole = useSelector((store) => store?.user?.userV2?.companyDetails?.type);

  const [imgLoader, setImageloader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);

  const handleDeleteImage = (index) => {
    const updatedPreviewUrls = [...previewUrls];

    const removedUrl = updatedPreviewUrls.splice(index, 1);

    if (removedUrl.length > 0) {
      setDeletedFiles((prevDeletedFiles) => [...prevDeletedFiles, removedUrl[0]]);
    }

    setPreviewUrls(updatedPreviewUrls);
  };

  const handleUploadClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleUploadedClick = (e) => {
    setIsImageModalVisible(true);
  };

  const handleModalVisibleCancel = () => {
    setIsImageModalVisible(false);
  };
  return (
    <Content className="content">
      <div className="personalDtlsSection__personalDetails">
        <Title className="personalDetails-title">{companyData?.company}</Title>
        <Form.Item className="editDetails-div">
          {isFormEditable && userRole?.toLowerCase() === 'admin' ? (
            <Button className="editSaveBtn" ghost type="primary" loading={loading} htmlType="submit">
              Save
            </Button>
          ) : null}
        </Form.Item>
        {!isFormEditable && userRole?.toLowerCase() === 'admin' ? (
          <Button
            className="editSaveBtn"
            onClick={() => dispatch(setIsCompanyFormEditable(true))}
            ghost
            type="primary"
            loading={loading}
          >
            Edit Profile
          </Button>
        ) : null}
      </div>
      <Row>
        <Col span={24} className="section">
          <span style={{ display: 'inline-block', marginBottom: '10px', color: '#1677ff', font: '20px' }}>
            {companyData?.name?.toUpperCase()}
          </span>
          <div className="avatar-title-container">
            <div className="avatar-wrapper">
              <Avatar size={95} src={logoPreviewUrl} icon={<UserOutlined />} style={{ borderRadius: '50%' }} />
              {!isFormEditable ? (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '-28px',
                    left: '5px',
                    borderRadius: '50%',
                    zIndex: 1,
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '-7px',
                    borderRadius: '50%',
                    zIndex: 1,
                    right: '7px',
                    boxShadow: '0px 3px 6px #00000029',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    border: '1px solid white',
                    padding: '3px',
                  }}
                >
                  <Upload maxCount={1} customRequest={({ file }) => handleUpload(file)} showUploadList={false}>
                    <img src={editImgIcon} alt="verified" style={{ width: '16px', verticalAlign: 'middle' }} />
                  </Upload>
                </div>
              )}
            </div>
            <div className="titles">
              <div>
                <p>{stats?.followers || 0}</p> <p>Followers</p>
              </div>
              <div>
                <p>{stats?.following || 0}</p> <p>Following</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Col>
        <Row>
          <Space direction="vertical">
            <Form.Item
              label={
                <span style={{ display: 'flex', alignItems: 'center', width: 'max-content' }}>
                  Company RERA registration id:{' '}
                  {companyData?.reraCertificates?.reraCertificate && (
                    <div style={{ marginLeft: '10px' }}>
                      <img
                        src={verifiedIcon}
                        alt="verified"
                        width="17px"
                        height="17px"
                        className="verified-icon"
                        style={{ verticalAlign: 'text-top' }}
                      />
                      <Button
                        type="link"
                        className="anchor-button"
                        style={{ paddingLeft: 0, fontSize: '16px' }}
                        onClick={handleUploadedClick}
                      >
                        Uploaded
                      </Button>
                    </div>
                  )}
                </span>
              }
              name="reraId"
              // rules={[
              //   {
              //     message: 'Please input valid RERA Id.',
              //     pattern: /^[A-Z0-9]{10,12}$/,
              //   },
              // ]}
              className={!isFormEditable ? 'form-item-non-editable' : ''}
            >
              {isFormEditable ? (
                // <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <Input
                  placeholder="Please fill in, if you have one"
                  className="companydata-input"
                  suffix={
                    <Button className="anchor-button" onClick={handleUploadClick}>
                      Upload
                    </Button>
                  }
                />
              ) : (
                // </div>
                <div className="single-line">
                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => <Text strong>{getFieldValue('reraId')}</Text>}
                  </Form.Item>
                </div>
              )}
            </Form.Item>
            <Modal
              title="Personal RERA Registration Certificate"
              visible={isImageModalVisible}
              onCancel={handleModalVisibleCancel}
              footer={null}
              centered
            >
              <div style={{ textAlign: 'center' }}>
                {companyData?.reraCertificates?.reraCertificate ? (
                  <>
                    <img
                      src={companyData?.reraCertificates?.reraCertificate}
                      alt="Uploaded"
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                    {/* Check if the uploaded file is a PDF */}
                    {companyData?.reraCertificates?.reraCertificate && (
                      <div style={{ marginTop: '20px' }}>
                        <Button
                          type="primary"
                          onClick={() => window.open(companyData?.reraCertificates?.reraCertificate)}
                        >
                          Download File
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <p>No image available</p>
                )}
              </div>
            </Modal>
            {/* Modal for Drag and Drop Upload */}
            <Modal visible={isModalVisible} onCancel={handleModalCancel} footer={null}>
              <Dragger
                name="file"
                multiple={false}
                beforeUpload={(file) => {
                  const isAllowedType = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(file.type);
                  const isAllowedSize = file.size / 1024 / 1024 < 5;

                  if (!isAllowedType) {
                    console.log('You can only upload JPEG, JPG, PNG, or PDF files!');
                    return false;
                  }
                  if (!isAllowedSize) {
                    console.log('File must be smaller than 5MB!');
                    return false;
                  }
                  handleUpload(file, 'reraCertificate');
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Drag and Drop</p>
                <p className="ant-upload-hint">your file here or upload from your gallery</p>
                <p className="ant-upload-hint" style={{ color: '#0080FC' }}>
                  Only JPEG, JPG, PNG, and PDF files with a max size of 5MB
                </p>
              </Dragger>
            </Modal>
            {/* Other fields */}
            <Form.Item
              label="Head Office: "
              name="headOffice"
              className={!isFormEditable ? 'form-item-non-editable' : ''}
            >
              {isFormEditable ? (
                <Input placeholder="Enter Head Office Address" className="companydata-input" />
              ) : (
                <div className="single-line">
                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => <Text strong>{getFieldValue('headOffice')}</Text>}
                  </Form.Item>
                </div>
              )}
            </Form.Item>

            <Form.Item
              label={`Industry Type: `}
              name="industryType"
              className={!isFormEditable ? 'form-item-non-editable' : ''}
              rules={[
                {
                  required: true,
                  message: 'Please enter Industry Type.',
                },
              ]}
            >
              {isFormEditable ? (
                <Input placeholder="Enter Industry Type" className="companydata-input" />
              ) : (
                <div className="single-line">
                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => <Text strong>{getFieldValue('industryType')}</Text>}
                  </Form.Item>
                </div>
              )}
            </Form.Item>

            <Form.Item
              label="Website Link: "
              name="websiteLink"
              className={!isFormEditable ? 'form-item-non-editable' : ' '}
            >
              {isFormEditable ? (
                <Input placeholder="https://" className="companydata-input" />
              ) : (
                <div className="single-line">
                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => <Text strong>{getFieldValue('websiteLink')}</Text>}
                  </Form.Item>
                </div>
              )}
            </Form.Item>

            <Form.Item
              label="Company Strength: "
              name="companyStrength"
              className={!isFormEditable ? 'form-item-non-editable' : ''}
            >
              {isFormEditable ? (
                <Input placeholder="Enter Company Strength" className="companydata-input" />
              ) : (
                <div className="single-line">
                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => <Text strong>{getFieldValue('companyStrength')}</Text>}
                  </Form.Item>
                </div>
              )}
            </Form.Item>
          </Space>
        </Row>
        <Row className="section" style={{ display: 'block' }}>
          <h4 className="bold">Photo Gallery</h4>
          <br />
          <div className="director">
            {isFormEditable && (
              <Form.Item name="imageURL">
                <div className="d-flex g-20">
                  <Upload
                    listType="picture-card"
                    className="uploadImage-company"
                    multiple
                    accept=".png,.jpg,.jpeg,.avif"
                    customRequest={({ file }) => {}}
                    showUploadList={false}
                    onChange={({ fileList }) => {
                      handleUploadPhotoGallery(fileList.map((file) => file.originFileObj));
                    }}
                  >
                    {imgLoader ? (
                      <Spin indicator={<LoadingOutlined />} />
                    ) : (
                      <>
                        <span style={{ fontSize: '43px' }}>+</span>
                      </>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            )}
            {/* Display preview images */}

            <Form.Item name="imageURL">
              <div className="uploadedImagesWrapper">
                {previewUrls?.length > 0 &&
                  previewUrls?.map((url, index) => (
                    <div className="uploadedImageContainer" key={index}>
                      <img src={url} alt={`uploaded-img-${index}`} className="uploadedImage" />
                      {isFormEditable && (
                        <span className="delete-icon" onClick={() => handleDeleteImage(index)}>
                          <CloseCircleOutlined />
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </Form.Item>
          </div>
        </Row>
        <Row span={24} className="section">
          <span>
            <Reviews averageRating={companyData?.averageRating} reviewsList={organizationReviews} />
            <span className="font12 ">Total Reviews: -</span>
          </span>
        </Row>
      </Col>
    </Content>
  );
};

export default Organization;
