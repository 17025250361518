import { client, clientV2, docClientV2 } from '../../utils/apiClient';
import { asyncHandler } from '../../utils/asyncHandler';

export const fetchUserOnboardingDataAPI = async (userId) => {
  try {
    const response = await clientV2.get(`/user/${userId}`);
    return response.data.response;
  } catch (error) {
    console.error(error);
  }
};

export const postOnboardingData = async (param, userId) => {
  try {
    const response = await clientV2.post(`/user/${userId}/profile-details?type=${param?.pageName}`, param?.body);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const postOnboardingDataForLinkedIn = async (param, userId) => {
  const [data, err] = await asyncHandler(client.patch(`/auth/${userId}/linkedin/user-data`, param?.body));
  if (err) return err;
  return data;
};

export const fetchUserPoints = async (userId) => {
  try {
    const response = await client.get(`/user/${userId}/points`);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const registerCrm = async (payload, userId) => {
  try {
    const response = await client.post(`/onboarding/${userId}/register-crm`, payload);
    return response;
  } catch (err) {
    console.error(err);
  }
};
export const registerFms = async (payload, userId) => {
  try {
    const response = await client.post(`/onboarding/${userId}/register-fms`, payload);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const registerOkr = async (payload, userId) => {
  try {
    const response = await client.post(`/onboarding/${userId}/register-okr`, payload);
    return response;
  } catch (err) {
    console.error(err);
  }
};
// --------------V2--------------------
// Create company
export const createCompany = async (userId, payload) => {
  const [data, err] = await asyncHandler(clientV2.post(`user/${userId}/company`, payload));
  if (err) return err;
  return data;
};
// associate company

export const associateCompany = async (userId, payload) => {
  try {
    const response = await clientV2.post(`user/${userId}/associate-company`, payload, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
//get company details
export const getCompanyDetail = async (companyId, userId) => {
  try {
    const response = await clientV2.get(`user/${userId}/company/uuid/${companyId}`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

//get company details
export const getCompanyDirectorDetail = async (companyId, userId) => {
  try {
    const response = await clientV2.get(`user/${userId}/company/directors/${companyId}`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// get company follow details
export const getCompanyFollowDetails = async (payload, userId) => {
  try {
    const response = await clientV2.get(`user/${userId}/follow/stats?ownerId=${payload?.ownerId}&type=company_post`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
// get all companies
export const getAllCompanyDetails = async (userId) => {
  try {
    const response = await clientV2.get(`user/${userId}/company`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
// get all companies by search
export const getAllCompanyDetailsBySearch = async (companyName, userId) => {
  try {
    const response = await clientV2.get(`user/${userId}/company/${companyName}`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
// get all industries
export const getAllIndustriesDetails = async (userId) => {
  try {
    const response = await clientV2.get(`user/${userId}/industry`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
// get all industries
export const getIndustryDetails = async (userId, industryId) => {
  try {
    const response = await clientV2.get(`user/${userId}/industry/${industryId}`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
// get offering details
export const updateProfileClassificationAPI = async (userId, payloadBody) => {
  try {
    const response = await clientV2.patch(`/user/${userId}/profile-classification`, payloadBody?.payload, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
// get offering details
export const getOfferingData = async (userId, type) => {
  try {
    const response = await clientV2.get(`/user/${userId}/offerings/${type}`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
// ---------Offering----------

// ---------Preferences----------

// add preferneces data
export const addUserPreferences = async (userId, payload) => {
  try {
    const response = await clientV2.post(`/user/${userId}/preferences`, payload, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
// get preferneces details
export const getUserPreferences = async (userId) => {
  try {
    const response = await clientV2.get(`/user/${userId}/preferences`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
// get preferneces list

export const getUserPreferenceList = async (userId, param) => {
  try {
    let response;
    if (param?.offerings) {
      response = await clientV2.get(
        `/user/${userId}/preferences/list?type=${param?.type}&offering=${param.offerings}`,
        {
          authorization: true,
        },
      );
    } else {
      response = await clientV2.get(`/user/${userId}/preferences/list?type=${param?.type}`, {
        authorization: true,
      });
    }

    return response;
  } catch (err) {
    console.error(err);
  }
};

// ---------Select Plan----------
// add select Plan
export const postSelectPlanData = async (payload, userId) => {
  try {
    const response = await client.post(`/onboarding/contact-us/${userId}`, payload, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

//add directors details
export const addCompanyDirector = async (companyId, userId, directorData) => {
  try {
    const response = await clientV2.post(`user/${userId}/company/directors/${companyId}`, directorData, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error('Error adding director:', err);
    throw err;
  }
};

//update directors details
export const updateCompanyDirector = async (companyId, userId, directorId, updatedData) => {
  try {
    const response = await clientV2.patch(
      `/user/${userId}/company/directors/${directorId}/company/${companyId}`,
      updatedData?.body,
      {
        authorization: true,
      },
    );
    return response;
  } catch (err) {
    console.error('Error updating director:', err);
    throw err;
  }
};

export const updateCompanyRERA = async (companyId, userId, updatedData) => {
  try {
    const response = await docClientV2.patch(`/user/${userId}/company/uuid/property/${companyId}`, updatedData, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error('Error updating director:', err);
    throw err;
  }
};

export const fetchCompanyReraDetails = async (userId, companyId) => {
  try {
    const response = await clientV2.get(`/user/${userId}/company/uuid/${companyId}`, {
      authorization: true,
    });
    return response;
  } catch (err) {
    console.error('Error getting rera details:', err);
    throw err;
  }
};
