import { CameraOutlined, FileOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSocialGroup, getSocialMembers, uploadPostImage } from '../../../../features/socialAppSlice';
import { bytesToMB } from '../../posts/addPostForms/addPostForm.utils';
import ReactQuillComponent from '../../posts/addPostForms/common/ReactQuillComponent';
import './createGroupScreen.scss';
import SearchGroupMember from './SearchGroupMember';
const CreateGroupScreen = ({ onExitCreation }) => {
  const [formDetails] = Form.useForm();
  const dispatch = useDispatch();
  const [imgUrls, setImgUrls] = useState([]);
  const [imgLoader, setImageloader] = useState(false);
  const [imgNames, setImgNames] = useState([]);
  const [members, setMembers] = useState([]);
  const [terms, setTerms] = useState(false);

  useEffect(() => {
    dispatch(
      getSocialMembers({
        searchBy: '',
      }),
    )?.then((res) => {
      const data = res?.payload?.data?.response?.data?.results;
      if (data) {
        setMembers(data);
      }
    });
  }, []);

  // handle image upload
  const handleUpload = async (file) => {
    if (bytesToMB(file?.size) <= 5) {
      const formData = new FormData();
      formData.append('files', file);
      setImageloader(true);
      dispatch(uploadPostImage(formData))?.then((res) => {
        setImageloader(false);
        message.success(`Image uploaded successfully...`);
        if (res?.payload?.data?.response?.data) {
          setImgNames((prev) => [...(prev || []), file?.name]);
          setImgUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
        }
      });
    } else {
      message.error('File size should not exceed 5 MB');
    }
  };

  //

  const handleFormSubmit = (e) => {
    if (imgUrls?.length == 0) {
      message.error('Please upload group image');
      return;
    }
    if (!terms) {
      message.error('Please accept Terms & Conditions');
      return;
    }
    const payload = {
      name: e?.group_name,
      imageUrl: imgUrls?.[0],
      purpose: e?.group_purpose,
      rules: e?.group_rule,
      members: e?.group_members,
      acceptedTerms: true,
    };
    dispatch(createSocialGroup(payload))?.then((res) => {
      if (res?.payload) {
        message.success('Group Created Successfully');
        onExitCreation();
      }
    });
  };

  return (
    <div className="yourGroupContainer h-100">
      <Form layout="vertical" form={formDetails} onFinish={handleFormSubmit}>
        <div className="d-flex jc-between">
          <div className="font22 fontDark-2 mb-20">
            {' '}
            <LeftOutlined onClick={onExitCreation} /> Create New Group
          </div>
          <Button htmlType="submit" type="primary">
            {' '}
            <span className="font12">Create Group</span>
          </Button>
        </div>
        <div className="mt-10">
          <div className={`formContainer`}>
            <div className="d-flex d-column">
              <Form.Item
                name="group_name"
                required
                rules={[
                  {
                    required: true,
                    message: 'Add Group Name!',
                  },
                ]}
              >
                <Input
                  style={{ height: 50, background: '#f4f4f4' }}
                  prefix={
                    <Upload
                      listType="picture-card"
                      className="GroupUploadImage"
                      accept=".png,.jpg,.jpeg,.avif"
                      customRequest={({ file }) => handleUpload(file)}
                      showUploadList={false}
                    >
                      {imgLoader ? (
                        <Spin indicator={<LoadingOutlined />} />
                      ) : (
                        <CameraOutlined
                          className="font24 mr-5 fontLight"
                          style={{ marginRight: 10, color: '#707070', border: 'none' }}
                        />
                      )}
                    </Upload>
                  }
                  placeholder="Add Group Name"
                  min={0}
                />
              </Form.Item>
              {imgNames?.length > 0 && (
                <div className="d-flex d-column mt-10 g-10" style={{ maxHeight: '125px', overflow: 'scroll' }}>
                  {imgNames?.map((elem, index) => (
                    <div className="upoadedFileContainer" key={index}>
                      <FileOutlined /> {elem}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <Form.Item
              name="group_purpose"
              required
              rules={[
                {
                  required: true,
                  message: 'Add purpose of group!',
                },
              ]}
            >
              <ReactQuillComponent
                style={{ background: '#f4f4f4', border: 'none' }}
                placeholder="Purpose of the group"
              />
            </Form.Item>
            <Form.Item
              name="group_rule"
              required
              rules={[
                {
                  required: true,
                  message: 'Set Rule!',
                },
              ]}
            >
              <ReactQuillComponent style={{ background: '#f4f4f4', border: 'none' }} placeholder="Set Rule" />
            </Form.Item>
            <Form.Item
              name="group_members"
              required
              rules={[
                {
                  required: true,
                  message: 'Select Members',
                },
              ]}
            >
              {members?.length > 0 && <SearchGroupMember members={members} />}
            </Form.Item>
            <Form.Item name="terms_conditions">
              <Checkbox onClick={() => setTerms((toggle) => !toggle)} checked={terms} name="terms_conditions" />{' '}
              <span className="fontBlue text-underline font700 font16 ml-10">Terms & Conditions</span>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateGroupScreen;
